import App from 'next/app';
import Router from 'next/router';
import * as gtag from '~/lib/gtag';
// import * as Sentry from '@sentry/node';
// import { RewriteFrames } from '@sentry/integrations';
import '~/assets/scss/main.scss';

// Sentry.init({
//   dsn: config.sentryDSN,
//   enabled: !!config.sentryDSN,
//   integrations: [new RewriteFrames({
//     root: global.rootDir
//   })]
// });

// Router.events.on('routeChangeComplete', (path) => window.ga && window.ga(
//   'send', 'pageview', {
//     location: `${config.rootUrl}${path}`,
//     page: path
//   }
// ));

Router.events.on('routeChangeComplete', (url) => gtag.pageview(url));

class MyApp extends App {
  render() {
    const { Component, pageProps } = this.props;

    // Workaround for https://github.com/zeit/next.js/issues/8592
    const { err } = this.props;
    const modifiedPageProps = { ...pageProps, err };

    /* eslint-disable */
    return <Component {...modifiedPageProps} />;
    /* eslint-enable */
  }
}

export default MyApp;
